<template>
  <v-row>
    <v-col
      cols="12"
      md="4"
      offset-md="1"
    >
      {{ tarifa.name }}
      (<b>{{ tarifa.code }}</b>)
    </v-col>
    <v-col
      cols="12"
      md="3"
    >
      <v-select
        v-model="tarifa.category_id"
        :disabled="$store.state.app.onlyShow"
        :items="categoriesTarifarios"
        :label="$t('menu.categoriesTarifarios')"
        outlined
        dense
        clearable
        hide-details
        item-text="name"
        item-value="id"
        @change="changeSlugCategory"
      >
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-title>
              {{ $t('menu.categoriesTarifarios') }}
            </v-list-item-title>
          </v-list-item>
        </template>
      </v-select>
    </v-col>
    <v-col
      cols="12"
      md="2"
    >
      <v-tooltip
        top
        color="error"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            x-small
            v-bind="attrs"
            color="error"
            outlined
            @click="deleteModal()"
            v-on="on"
          >
            <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('btn.delete') }}</span>
      </v-tooltip>
    </v-col>

    <!-- DELETE -->
    <v-dialog
      v-model="isAddDelete"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.delete')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              {{ $t('msg.addDelete', { item: tarifa.name }) }}
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isAddDelete = !isAddDelete"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="addDelete"
            @click="confirmDelete()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
  mdiLinkVariantPlus,
  mdiFileReplaceOutline,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    lote: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    hotel: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    tarifa: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    categoriesTarifarios: {
      type: Array,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      isLoadingSuplementos: true,
      isDialogSuplemento: false,
      addDelete: false,
      isAddDelete: false,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
        mdiLinkVariantPlus,
        mdiFileReplaceOutline,
      },
      loading: false,
      permisos: sessionStorage.getItem('permisos_auth'),
      roomsHotel: [],

      itemsRooms: [],
      show: false,
      nameRoom: '',
    }
  },
  computed: {
    ...mapState({
      hotelsChanges: state => state.app.hotelsChanges,
    }),
  },

  methods: {
    ...mapMutations([
      'addCupoRoomSeasonContrateHotel',
      'deleteCupoRoomSeasonContrateHotel',
      'replicaCupoRoomSeasonContrateHotel',
      'replicaCupoSeasonContrateHotel',
      'editReleaseByCupoSeasonContrateHotel',
    ]),
    deleteModal() {
      this.isAddDelete = true
    },
    confirmDelete() {
      this.addDelete = true

      this.axios
        .post(
          `lotes-contrate-dingus/delete-tarifa/${this.lote.id}`,
          { tarifa: this.tarifa.name },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.$parent.$emit('getLote')
        })
        .catch(error => console.log(error))
        .finally(() => {
          this.resetItem()
        })
    },
    resetItem() {
      this.addDelete = false
      this.isAddDelete = false
    },
    changeSlugCategory() {
      if (this.tarifa.category_id) {
        if (this.categoriesTarifarios.filter(e => e.id === this.tarifa.category_id).length > 0) {
          this.tarifa.category_slug = this.categoriesTarifarios.filter(e => e.id === this.tarifa.category_id)[0].slug
        }
      }
    },
  },
}
</script>
