<template>
  <v-row>
    <v-col
      cols="12"
      md="4"
      offset-md="1"
    >
      {{ plan.name }}
    </v-col>
    <v-col
      cols="12"
      md="3"
    >
      <v-select
        v-model="plan.id"
        :disabled="$store.state.app.onlyShow"
        :items="plansFree"
        :label="$t('lbl.plan')"
        outlined
        dense
        clearable
        hide-details
        item-text="siglas"
        item-value="id"
      >
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-title>
              {{ $t('lbl.plan') }}
            </v-list-item-title>
          </v-list-item>
        </template>
      </v-select>
    </v-col>
    <v-col
      cols="12"
      md="2"
    >
      <v-tooltip
        top
        color="primary"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            x-small
            v-bind="attrs"
            color="primary"
            outlined
            v-on="on"
            @click="addModal"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('btn.insert') }}</span>
      </v-tooltip>

      <v-tooltip
        top
        color="primary"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            x-small
            v-bind="attrs"
            color="primary"
            outlined
            class="ml-5"
            :disabled="!plan.id"
            v-on="on"
            @click="showRemplace"
          >
            <v-icon>{{ icons.mdiFileReplaceOutline }}</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('btn.remplace') }}</span>
      </v-tooltip>

      <!--<v-tooltip
        top
        color="primary"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            x-small
            v-bind="attrs"
            color="primary"
            outlined
            class="ml-5"
            :disabled="!plan.id"
            v-on="on"
            @click="showAsociate"
          >
            <v-icon>{{ icons.mdiLinkVariantPlus }}</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('btn.asociate') }}</span>
      </v-tooltip>-->
    </v-col>

    <!-- ADD CHANGE NOM -->
    <v-dialog
      v-model="isAddChange"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.insert')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          {{ $t('msg.addChange', { item: plan.name }) }}
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isAddChange = !isAddChange"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="addChange"
            @click="confirmAddChange()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--MODAL REMPLACE-->
    <v-dialog
      v-model="isAddRemplace"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.remplace')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          {{ $t('msg.addRemplace', { remplace:plan.name, item: plans.filter(e => e.id === plan.id).length > 0 ? plans.filter(e => e.id === plan.id)[0].name : '' }) }}
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isAddRemplace = !isAddRemplace"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="addChange"
            @click="confirmAddRemplace()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--MODAL ASOCIATE-->
    <v-dialog
      v-model="isAddAsociate"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.asociate')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          {{
            $t('msg.addAsociate', { asociate: plan.name, item: plans.filter(e => e.id === plan.id).length > 0
              ? plans.filter(e => e.id === plan.id)[0].name
              : ''
            })
          }}
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isAddAsociate = !isAddAsociate"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="addChange"
            @click="confirmAddAsociate()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
  mdiLinkVariantPlus,
  mdiFileReplaceOutline,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    lote: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    hotel: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    plan: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    plans: {
      type: Array,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      isLoadingSuplementos: true,
      isDialogSuplemento: false,
      isAddChange: false,
      addChange: false,
      isAddDelete: false,
      isAddRemplace: false,
      isAddAsociate: false,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
        mdiLinkVariantPlus,
        mdiFileReplaceOutline,
      },
      loading: false,
      permisos: sessionStorage.getItem('permisos_auth'),
      roomsHotel: [],

      itemsRooms: [],
      show: false,
      nameRoom: '',
    }
  },
  computed: {
    ...mapState({
      hotelsChanges: state => state.app.hotelsChanges,
    }),
    plansFree() {
      const result = []

      this.plans.forEach(element => {
        if (element) {
          let esta = false
          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < this.hotel.plans.length; index++) {
            if (this.hotel.plans[index].id === element.id && index !== this.pos) {
              esta = true
            }
          }
          if (!esta) {
            result.push(element)
          }
        }
      })

      return result
    },
  },

  /* created() {
    this.allRooms.forEach(element => {
      if (this.hotel.rooms_id.includes(element.id)) {
        this.roomsHotel.push(element)
      }
    })

    if (this.roomsHotel.length === 1) {
      this.room.room_id = this.roomsHotel[0].id
    }

    if (this.roomsSeasonContrateHotel.length === 1) {
      if (this.roomsSeasonContrateHotel[0].rooms_id.length === 1) {
        this.room.rooms_id = this.roomsSeasonContrateHotel[0].rooms_id
      }
    }
  }, */
  methods: {
    ...mapMutations([
      'addCupoRoomSeasonContrateHotel',
      'deleteCupoRoomSeasonContrateHotel',
      'replicaCupoRoomSeasonContrateHotel',
      'replicaCupoSeasonContrateHotel',
      'editReleaseByCupoSeasonContrateHotel',
    ]),
    addModal() {
      this.isAddChange = true
    },
    confirmAddChange() {
      this.addChange = true
      const json = {
        planes: {
          name: this.plan.name,
          valueCsv: this.plan.name,
        },
      }

      this.axios
        .post(
          `lotes-contrate-hotetec/add-change/${this.lote.id}`,
          { type: 'planes', changes: json },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        // eslint-disable-next-line no-unused-vars
        .then(response => {

          // this.$parent.$emit('getLote')
        })
        .finally(() => {
          this.resetItem()
        })
    },
    showRemplace() {
      this.isAddRemplace = true
    },
    confirmAddRemplace() {
      this.addChange = true
      const json = {
        newValue: this.plan.name,
        typeName: null,
        valueCsv: this.plan.valueCsv,
        codeHot: this.hotel.codeHot,
        idValue: this.plan.id,
        type: 'planes',
      }

      this.axios
        .post(`lotes-contrate-hotetec/add-remplace/${this.lote.id}`, json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          // this.$toast.success(this.$t('msg.infoSuccess'))
        })
        .finally(() => {
          this.resetItem()
        })
    },
    showAsociate() {
      this.isAddAsociate = true
    },
    confirmAddAsociate() {
      this.addChange = true
      const json = {
        newValue: this.plan.name,
        typeName: null,
        valueCsv: this.plan.valueCsv,
        codeHot: this.hotel.codeHot,
        idValue: this.plan.id,
        type: 'planes',
      }

      this.axios
        .post(`lotes-contrate-hotetec/add-asociate/${this.lote.id}`, json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        // eslint-disable-next-line no-unused-vars
        .then(response => {
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => {
          this.resetItem()
        })
    },
    resetItem() {
      this.addChange = false
      this.isAddChange = false
      this.isAddDelete = false
      this.isAddRemplace = false
      this.isAddAsociate = false
    },
  },
}
</script>
